import axios, {AxiosResponse} from "axios";

// const clientURL: any = {
//     development: 'http://localhost:55551/',
//     production: 'https://julia.yovogames.com/'
// }

const apiURL: any = {
    development: 'http://localhost:55551/',
    production: 'https://api.kolomafamily.com/'
}

function newFormData(data: any, images?: any, video?: any) {
    const formData = new FormData()
    if (data != null) formData.append('Data', typeof data === 'string' ? data : JSON.stringify(data))
    if (video != null) formData.append('Video-0', video)
    if (images != null) {
        if (!Array.isArray(images)) {
            formData.append('Image-0', images)
        }
        else {
            for (let i = 0; i < images.length; i++) {
                formData.append(`Image-${i}`, images[i])
            }
        }
    }
    return formData
}

function getSrc(image: string, dir: string): string {
    if (!image || !dir) return ''
    return `${apiURL[process.env.NODE_ENV]}${dir}/${image}`
}

function logIn(data: any): Promise<AxiosResponse<any>> {
    const formData = newFormData(data)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/login`, formData, {responseType: 'json', headers: {'Authorization': localStorage.jsa_sTok || ''}})
}

function logOut(): Promise<AxiosResponse<any>> {
    return axios.get(`${apiURL[process.env.NODE_ENV]}admin/logout`, {responseType: 'json', headers: {'Authorization': localStorage.jsa_sTok || ''}})
}

function getMenu(): Promise<AxiosResponse<any>> {
    return axios.get(`${apiURL[process.env.NODE_ENV]}admin/get/menu`, {responseType: 'json'})
}

// function register(data: any): Promise<AxiosResponse<any>> {
//     const formData = newFormData(data)
//     return axios.post(`${clientURL[process.env.NODE_ENV]}register`, formData, {responseType: 'json'});
// }

//--------------------------------------------------------------------------------------------------------------------//
function get(key: string): Promise<AxiosResponse<any>> {
    if (key === 'visitors') {
        return axios.get(`${apiURL[process.env.NODE_ENV]}admin/get-${key}`, {responseType: 'json', headers: {'Authorization': localStorage.jsa_sTok || ''}})
    }
    return axios.get(`${apiURL[process.env.NODE_ENV]}admin/get/${key}`, {responseType: 'json', headers: {'Authorization': localStorage.jsa_sTok || ''}})
}
//--------------------------------------------------------------------------------------------------------------------//
function insert(key: string, data: any, images?: any, video?: any): Promise<AxiosResponse<any>> {
    const formData = newFormData(data, images, video)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/insert/${key}`, formData, {responseType: 'json', headers: {'Authorization': localStorage.jsa_sTok || '', 'Accept': 'application/mp4'}})
}

function update(key: string, data: any, images?: any, video?: any): Promise<AxiosResponse<any>> {
    console.log('IMAGES: ', images)
    const formData = newFormData(data, images, video)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/update/${key}`, formData, {responseType: 'json', headers: {'Authorization': localStorage.jsa_sTok || '', 'Content-Type': '*/*'}})
}

function remove(key: string, data: any): Promise<AxiosResponse<any>> {
    const formData = newFormData(data)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/delete/${key}`, formData, {responseType: 'json', headers: {'Authorization': localStorage.jsa_sTok || ''}})
}

interface APISERVICE {
    getSrc: (image: string, dir: string) => string,
    logIn: (data: any) => Promise<AxiosResponse<any>>,
    logOut: () => Promise<AxiosResponse<any>>,
    get: (key: string) => Promise<AxiosResponse<any>>,
    insert: (key: string, data: any, images?: any, video?: any) => Promise<AxiosResponse<any>>,
    update: (key: string, data: any, images?: any, video?: any) => Promise<AxiosResponse<any>>,
    remove: (key: string, data: any) => Promise<AxiosResponse<any>>
}

const apiService: APISERVICE = {
    getSrc,
    logIn,
    logOut,

    get,

    insert,
    update,
    remove
}

export default apiService
